import React from 'react';
import Stack from 'react-bootstrap/Stack';

function Sections({ sections, title_block: TitleBlock = ({ children }) => <h1 className="my-5">{children}</h1> }) {
  return sections.map((section, i) => (
    <div key={`home-section-${i}`} id={`home-section-${section.title}`}>
      <div className="section-title"><TitleBlock>{section.title}</TitleBlock></div>
      <Stack gap={3}>
        {section.items.map((item, j) => (
          <div key={`section-${i}-item-${j}`}>
            {item}
          </div>
        ))}
      </Stack>
    </div>
  ));
}

export default Sections;
 