import { Accordion } from 'react-bootstrap';
import Sections from './helpers/sections';
import PageTemplate from './helpers/pagetemp';
import faq_sections from '../update/faq';

function MyFAQ() {
  const renderFaqItems = (faqs) => (
    <Accordion>
      {faqs.map((faq, index) => (
        <Accordion.Item eventKey={index.toString()} key={index}>
          <Accordion.Header>{faq.question}</Accordion.Header>
          <Accordion.Body>{faq.answer}</Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );

  const sections = faq_sections.map((section) => ({
    title: section.title,
    items: [renderFaqItems(section.faqs)]
  }));

  return (
    <PageTemplate id="faqpage">
      <h1 className="my-5">FAQ</h1>
      <Sections sections={sections} title_block={({ children }) => <h4 style={{textAlign: 'center', marginBottom: '2rem'}}>{children}</h4>} />
    </PageTemplate>
  );
}

export default MyFAQ;
