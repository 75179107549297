import { Container, Row, Col, Card, Stack, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import Sections from './helpers/sections';
import PageTemplate from './helpers/pagetemp'; 

import meetings from '../update/meetings.js';

process.env.TZ = 'America/Los_Angeles';

function Mymeetings() {

  

  // Sort the meetings by date
  meetings.sort((a, b) => a.date - b.date);

  // Separate meetings into upcoming and past lists
  const currentDate = new Date();
  const upcomingMeetings = meetings.filter(meeting => meeting.date >= currentDate);
  const pastMeetings = meetings.filter(meeting => meeting.date < currentDate);

  // Group meetings for display in rows
  const groupMeetings = (meetings) => {
    const grouped = [];
    for (let i = 0; i < meetings.length; i += 3) {
      grouped.push(meetings.slice(i, i + 3));
    }
    return grouped;
  };

  // Generate meeting sections for upcoming and past
  const generateMeetingSections = (meetings) => {
    return (meetings.length === 0 ? [<p style={{ textAlign: 'center' }}><strong>None</strong></p>] : 
    groupMeetings(meetings).map((group, groupIndex) => (
      <Row key={groupIndex} className="mb-4">
        {group.map((meeting, index) => (
          <Col key={index} md={4} xs={12}>
            {MeetingCard({ meeting, index })}
          </Col>
        ))}
      </Row>
    )));
  };

  var sections = [
    {
      "title": "Upcoming",
      "items": generateMeetingSections(upcomingMeetings)
    },
    {
      "title": "Past",
      "items": generateMeetingSections(pastMeetings)
    }
  ];

  return <PageTemplate id="meetingpage">
    <h1 className="my-5">Events</h1>
    <Sections sections={sections} title_block={({ children }) => <h4 style={{textAlign: 'center', marginBottom: '2rem'}}>{children}</h4>} />
  </PageTemplate>
}

function MeetingCard({ meeting, index }) {
  return <Card>
    <Card.Body>
      <div className="meeting-card-container">
        <div className="meeting-card-date-block">
          <div className="meeting-card-date-month">
            {meeting.date.toLocaleString('default', { month: 'short' }).toUpperCase()}
          </div>
          <div className="meeting-card-date-day">
            {meeting.date.getDate().toString().padStart(2, '0')}
          </div>
        </div>

        <div className="meeting-card-info">
          <Card.Title className="meeting-card-title">{meeting.name}</Card.Title>

          {IconText({
            icon: <FaMapMarkerAlt />, text:
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-${index}`}>
                    {meeting.LongLocation}
                  </Tooltip>
                }
              >
                <span className="meeting-card-location">
                  {meeting.ShortLocation}
                </span>
              </OverlayTrigger>
          })}
          <IconText icon={<FaClock className="meeting-card-clock-icon" />} text={meeting.Time} />

          <div className="meeting-card-spacing"></div>

          <Card.Text className="meeting-card-desc">{meeting.Description}</Card.Text>
        </div>
      </div>
    </Card.Body>
  </Card>
}

function IconText({ icon, text }) {
  return <> <Card.Text
    className="icon-text"
  >
    <span className="icon">{icon}</span>
    <span>
      {text}
    </span>
  </Card.Text>
    <div></div>
  </>
}

export default Mymeetings;
