import { Container, Stack } from 'react-bootstrap';
import AlternatingImages from '../helpers/alternatingimages';

import arml_2022 from '../images/2022-arml.jpg';
import arml_2023 from '../images/2023-arml.jpg';
import arml_2024 from '../images/2024-arml.jpg';
import Sections from '../helpers/sections';
import PageTemplate from '../helpers/pagetemp';

function MyARML() {
    
    var about_section = [
        <div className="spacious-text"> 
            The American Regions Mathematics League (ARML) is an annual, national high school mathematics team competition held simultaneously at four locations in the United States. The competition consists of several events, including a team round, a power round, an individual round, and a relay round, with both individual and team rankings across two divisions. The competition is held near the start of June each year.
        </div>
    ]
 
    var past_result_sections = AlternatingImages({
        sections: [
            {
                title: "",
                description: ( 
                    <>
                        <h5>2024</h5>
                        <p>
                            After our 2023 performance, our teams were promoted to the <strong>A division</strong>. Our top team placed
                            <span className="emphasized-text"> 18<sup>th</sup></span> in the A division among over
                            <strong> 100 teams</strong> and <strong>1600 students</strong> competing.
                        </p>
                    </>
                ),
                image: arml_2024
            },
            {
                title: "",
                description: (
                    <>
                        <h5>2023</h5>
                        <p>
                            For the first time in over a decade, we sent two teams to ARML, with 14 of the 15 members from the previous year returning. <br/><br/> Our top team placed
                            <span className="emphasized-text"> 3<sup>rd</sup></span> in the B division, with the top scoring individual placing
                            <span className="emphasized-text"> 23<sup>rd</sup></span> overall in the competition among over <strong>100 teams</strong> and <strong>1600 students</strong> competing.
                        </p>
                    </>
                ),
                image: arml_2023
            },
            {
                title: "",
                description: (
                    <>
                        <h5>2022</h5>
                        <p>
                            This was our first year competing post-Covid, with only one person who had previously competed at ARML in-person. We got
                            <span className="emphasized-text"> 8<sup>th</sup></span> place in the B division.
                        </p>
                    </>
                ),
                image: arml_2022
            }
        ],
        key: "past-results"
    });

    var description_section = [
        <div className="spacious-text">
            The teams in the A and B divisions receive the same sets of problems, but are ranked separately. Each team has 15 members, and there are five rounds:
            <ul>
                <li><strong>Team Round:</strong> The team works together to solve 10 computational problems in 20 minutes, with each problem worth 5 points.</li>
                <li><strong>Power Round:</strong> The team works together to solve 10-15 problems, some with multiple parts, over 60 minutes, with much of the round being proof-based. The round is worth 50 points in total, with the questions weighted based on difficulty.</li>
                <li><strong>Individual Round:</strong> Each team member works alone to solve 10 problems in 60 minutes, with each problem worth 1 point. The problems come in pairs of two, with 10 minutes per pair. The odd numbered problems are usually significantly easier than the even numbered problems.</li>
                <li><strong>Relay Round:</strong> The team is split into five groups of three. Each group designates one person for problems 1, 2, and 3, with the latter two problems requiring the answers to the previous problem before they can be solved. There are two rounds of three problems (one per person), with only the third person's answer counting for points: 5 points if submitted before 3 minutes, and 3 points if submitted before 6 minutes.</li>
                <li><strong>Super Relay (Unofficial):</strong> A relay round involving the entire team, with answers being passed from person 1 up till person 8, as will as person 15 down till person 8.</li>
            </ul>
        </div>
    ]

    var sections = [
        {
            "title": "About",
            "items": about_section
        },
        {
            "title": "Description", 
            "items": description_section
        },
        {
            "title": "Past Results",
            "items": past_result_sections
        }
    ];

    return <PageTemplate id="arml-page">
        <h1 className="my-5">ARML</h1>
        <Sections sections={sections} title_block={({ children }) => (<h4>{children}</h4>)} />
    </PageTemplate>    
}

export default MyARML;
