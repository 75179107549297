const faq_sections = [
    {
        title: "General Information",
        faqs: [
            {
                question: "What is Oregon Math Circle?",
                answer: "Oregon Math Circle (OMC) is an organization that helps middle and high school students explore mathematics beyond their school curriculum through problem-solving, competitions, and group discussions."
            },
            {
                question: "Who can join the Math Circle?",
                answer: "OMC is open to middle and high school students who have an interest in mathematics. Some events may require specific qualifications, but in general, any motivated student is welcome to participate."
            },
            {
                question: "When do the meetings take place?",
                answer: "Meetings are usually held on weekends. Check the event page for a detailed schedule."
            }
        ]
    },
    {
        title: "Competitions",
        faqs: [
            {
                question: "What are the competition practices?",
                answer: "Competition practices focus on preparing students for various math competitions, such as ARML, HMMT, and others. Students work on practice problems and learn advanced techniques."
            }
        ]
    },
    {
        title: "Team Selection",
        faqs: [
            {
                question: "How are teams selected for competitions?",
                answer: "Teams are selected based on TSTs (Team Selection Tests) and some other criteria (such as meeting attendance and POTD performance)."
            },
            {
                question: "What is the TST (Team Selection Test)?",
                answer: "The TST is a test with original problems written by OMC leaders that is used to select students for competition teams."
            }
        ]
    }
];

export default faq_sections;