import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useEffect, useState } from 'react';

import { GoogleLogin, useGoogleLogin, googleLogout } from '@react-oauth/google';
import { UserContext } from '../authenticate';

import { useContext } from 'react';
import { FaGoogle } from 'react-icons/fa';
import { VscNoNewline } from 'react-icons/vsc';


function Mynavbar() {

  return (
    <>
      {/* <div>
        <h2>React Google Login</h2>
        <br />
        <br />
        {profile ? (
          <div>
            <img src={profile.picture} alt="user image" />
            <h3>User Logged in</h3>
            <p>Name: {profile.name}</p>
            <p>Email Address: {profile.email}</p>
            <p>Email Address: {profile.picture}</p>
            <br />
            <br />
            <button onClick={logOut}>Log out</button>
          </div>
        ) : (
          <button onClick={() => login()}>Sign in with Google 🚀 </button>
        )}
      </div> */}
      <Navbar collapseOnSelect expand='lg' variant='light' className="py-lg-3" id="navbar">
        <Container>
          <Navbar.Brand href="/" className='d-flex align-items-center fs-4'>
            <img
              alt="Oregon Math Circle"
              src="logos/logo50.jpg"
              width="40"
              height="40"
              className="d-inline-block align-center me-2 me-lg-3"
            />{' '}
            Oregon Math Circle
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
            <Nav variant="underline" activeKey={window.location.pathname}>
              {Mynavdropdown({
                text: 'Competitions', children: [
                  { text: 'ARML', eventKey: '/arml' },
                  { text: 'HMMT', eventKey: '/hmmt' },
                  { text: 'Other', eventKey: '/other' }
                ]
              })}

              {[{ text: 'Meetings', eventKey: '/meetings' }, { text: 'Members', eventKey: '/members' }].map((item) => (
                Mynavitem(item)
              ))}

              {Mynavdropdown({
                text: 'More', children: [
                  { text: 'FAQ', eventKey: '/faq' },
                ]
              })} 

              {/* <MyGoogleButton /> */}

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

// function MyGoogleButton() {
//   const { profile, login, logOut } = useContext(UserContext);

//   const style = {
//     borderRadius: '50%',
//     width: '2em',
//     height: '2em',
//     padding: '0',
//     display: 'flex', 
//     alignItems: 'center',
//     justifyContent: 'center', 
//     border: 'black 1px solid'
//   }

//   return <Nav.Item style={{
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center'
//   }}>
//     {
//       (profile ? <Button onClick={() => logOut()} style={{
//         backgroundImage: `url(${profile.picture})`,
//         backgroundSize: 'cover',
//         backgroundPosition: 'center',
//         ...style
//       }} variant='light'>
//       </Button> :
//         <Button onClick={() => login()} style={{
//           ...style
//         }} variant='light'>
//           <FaGoogle size='1.5em' color="rgb(0,0,0,0.7)" />
//         </Button>
//       )
//     }
//   </Nav.Item>
// }

function Mynavitem({ eventKey, text }) {
  return (
    <Nav.Item className="mx-auto" key={eventKey}>
      <Nav.Link href={eventKey} eventKey={eventKey}>{text}</Nav.Link>
    </Nav.Item>
  )
}

function Mynavdropdown({ text, children }) {
  // loop through children & if href matches current page, set isActive to 'active-parent'
  const [isActive, setIsActive] = useState('');
  useEffect(() => {
    children.forEach((child) => {
      console.log(child.eventKey, window.location.pathname, child.eventKey === window.location.pathname);
      if (child.eventKey === window.location.pathname) {
        setIsActive('active-bold');
      }
    });
  }, [children]);

  return (
    <NavDropdown title={text} className={`mx-auto collapsible-nav-dropdown ${isActive}`}>
      {children.map((child) => (
        Mynavdropdownitem(child)
      ))}
    </NavDropdown>
  )
}

function Mynavdropdownitem({ eventKey, text }) {
  return (
    <NavDropdown.Item className="dropdown-option"href={eventKey} eventKey={eventKey} key={eventKey}>{text}</NavDropdown.Item>
  )
}

export default Mynavbar;