import { Container, Stack } from 'react-bootstrap';
import AlternatingImages from '../helpers/alternatingimages';

import hmmt_2024 from '../images/2024-hmmt.jpg';
import hmmt_2023 from '../images/2023-hmmt.jpg';
import Sections from '../helpers/sections';
import PageTemplate from '../helpers/pagetemp';

function MyHMMT() {

    var about_section = [
        <div className="spacious-text"> 
            The Harvard-MIT Mathematics Tournament (HMMT) is a high school math competition held annually in November and February, with both competitions being independent. The tournament is run by students from Harvard University and the Massachusetts Institute of Technology. The competition consists of several rounds, and is sandwiched by a series of lectures and events. 
        </div>
    ]
 
    var past_result_sections = AlternatingImages({
        sections: [
            {
                title: "",
                description: ( 
                    <>
                        <h5>2024</h5>
                        <p>
                            We attended the HMMT competition (February) again for a second year. Our team came in <strong>42<sup>nd</sup></strong> out of around 80 teams.
                        </p>
                    </>
                ),
                image: hmmt_2024
            },
            {
                title: "",
                description: (
                    <>
                        <h5>2023</h5>
                        <p>
                            We attended the HMMT competition (February) for the first time in several years. Our top student placed <span className="emphasized-text">21<sup>st</sup></span> out of around <strong>500 students</strong>, qualifying for the HMIC (HMMT Invitational Competition). Two of our students also came in the <strong>top 25</strong> of the Combinatorics Round.
                        </p>
                    </>
                ),
                image: hmmt_2023
            }
        ],
        key: "past-results"
    });

    var description_section = [
        <div className="spacious-text">
            The <strong>November</strong> competition has 6 member teams, with four rounds: a general round, a theme round, a team round, and a guts round:
            <ul>
                <li><strong>General Round:</strong> 10 problems in 50 minutes</li>
                <li><strong>Theme Round:</strong> 10 problems in 50 minutes, with a theme that changes annually</li>
                <li><strong>Team Round:</strong> 10 proof-based problems in 60 minutes</li>
                <li><strong>Guts Round:</strong> 36 problems in 60 minutes, with the team working together to solve them. The problems are received in groups of 3, with increasing weightage of points as the difficulty increases. Each group of problems must be submitted before receiving the next group.</li>
            </ul>
            The <strong>February</strong> competition has 8 member teams, with three rounds: an algebra/number theory round, a geometry round, a combinatorics round, a team round, and a guts round:
            <ul>
                <li><strong>Algebra/Number Theory Round, Geometry Round, Combinatorics Round:</strong> 10 problems in 50 minutes of their respective topics.</li>
                <li><strong>Team Round, Guts Round:</strong> As described above.</li>
            </ul>
            For the past couple of years, we have attended only the February competition.
        </div>
    ]

    var sections = [
        {
            "title": "About",
            "items": about_section
        },
        {
            "title": "Description", 
            "items": description_section
        },
        {
            "title": "Past Results",
            "items": past_result_sections
        }
    ];

    return <PageTemplate id="hmmt-page">
        <h1 className="my-5">HMMT</h1>
        <Sections sections={sections} title_block={({ children }) => <h4>{children}</h4>} />
    </PageTemplate>
}

export default MyHMMT;
